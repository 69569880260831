import PropTypes from 'prop-types';

export default function Navbar(props) {
    return (
        <nav className="flex justify-between items-center p-3 bg-slate-5 0 shadow  text-lg box-border text-gray-500 flex-wrap gap-4 bg-slate-50 relative z-10 dark:bg-gray-800 dark:text-gray-300">
            <div className="flex gap-10 items-center">

                <div className="logo text-3xl hover:text-stone-900 dark:hover:text-stone-500 font-semibold">{props.title}</div>

                <ul className='flex gap-4 text-2xl'>
                    <li>
                        <a href="/" className=' hover:text-stone-900 dark:hover:text-stone-500'>Home</a>
                    </li>
                    <li>
                        <a href="/" className='hover:text-stone-900 dark:hover:text-stone-500'>About</a>
                    </li>
                </ul>
            </div>
            <div className='flex gap-3 flex-wrap'>
                <input className="px-2 py-1 rounded-lg border-2 border-gray-200 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600" type="text" placeholder='Search...' />

                <button className='rounded-lg px-4 py-1 bg-green-50 border-2 border-green-700 text-green-700 hover:bg-green-700 hover:text-green-50 duration-100 focus:ring focus:ring-green-300 focus:ring-opacity-40'>Search</button>

                <button className='rounded-lg px-4 py-1 bg-blue-50 border-2 border-blue-700 text-blue-700 hover:bg-blue-700 hover:text-green-50 duration-100 focus:ring focus:ring-blue-300 focus:ring-opacity-40' onClick={props.onTheme}>Theme</button>
            </div>
        </nav>
    )
}

Navbar.propTypes = {
    title: PropTypes.string,
}

Navbar.defaultProps = {
    title: "Title",
}