import { PropTypes } from "prop-types";

export default function Button(props){
    return (
        <button className={`px-4 py-1  text-white transition-colors duration-200 transform rounded-md  focus:outline-none ${props.bgColor} ${props.hoverBgColor} ${props.focusBgColor}`} onClick={props.onClick}>{props.text}</button>
    );
}

Button.propTypes = {
    bgColor: PropTypes.string,
    hoverBgColor: PropTypes.string,
    focusBgColor: PropTypes.string,
    text: PropTypes.string,
}

Button.defaultProps = {
    bgColor : "bg-gray-700",
    hoverBgColor : "hover:bg-gray-600",
    focusBgColor : "focus:bg-gray-700",
    
    onClick: null,
    text : "Button"
}