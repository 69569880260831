import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '../Btn/Btn.js';
import Copied from '../Copied/Copied.js';


export default function TextForm(props) {

    const [text, setText] = useState("");
    const [displayCopied, setDisplayCopied] = useState(null);
    const [textSummary, setTextSummary] = useState({ wordCount: 0, charCount: 0, lineCount: 0 });

    const handleText = {
        upCase: () => setText(text.toUpperCase()),
        lowCase: () => setText(text.toLowerCase()),
        inverseCase: () => {
            let value = text;
            let str = '';
            for (let char of value) {
                if ('a' < char && char < 'z') str += char.toUpperCase();
                else if ("A" < char && char < "Z") str += char.toLowerCase();
                else str += char;
            }
            setText(str);
        },
        alternateCase: () => {
            let value = text;
            let str = "";
            for (let index in value) {
                if (index % 2) str += value[index].toUpperCase();
                else str += value[index].toLowerCase();
            }
            setText(str);
        },
        clearText: () => {
            setText("")
            setTextSummary({ wordCount: 0, charCount: 0, lineCount: 0 });
        },
        copyText: () => {
            navigator.clipboard.writeText(text);
            setDisplayCopied(true);
            setTimeout(() => setDisplayCopied(null), 1500);
        }
    }

    const getWords = (str) => {
        let arr = str.trim().split(" ")
        let length = 0;
        for (let ele of arr) {
            if (ele) length++;
        }
        return length;
    }

    function handleChange(e) {
        let value = e.target.value;
        setText(value);
        setTextSummary({ wordCount: getWords(value), charCount: value.length, lineCount: value.split("\n").length });
    }



    return (
        <main className="flex flex-col w-9/12 mx-auto" >
            <section className="w-full max-w-2xl px-6 py-4  bg-slate-50 rounded-md shadow-md dark:bg-gray-800 my-10 self-center">

                <label htmlFor="text-area">
                    <h2 className="text-4xl font-semibold text-gray-800 dark:text-white">{props.heading}</h2>
                </label>

                <div className="mt-6">

                    <textarea className="block w-full h-40 px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 min-h-[10rem]" id="text-area" placeholder={props.placeholder} value={text} onChange={handleChange}></textarea>

                    <div className="mt-4 flex gap-4 flex-wrap">
                        <Button onClick={handleText.upCase} text="Upper Case"></Button>
                        <Button onClick={handleText.lowCase} text="Lower Case"></Button>
                        <Button onClick={handleText.inverseCase} text="Inverse Case"></Button>
                        <Button onClick={handleText.alternateCase} text="Alternate Case"></Button>
                        <Button onClick={handleText.copyText} text="Copy"></Button>
                        <Button onClick={handleText.clearText} text="Clear" bgColor="bg-red-700" hoverBgColor="hover:bg-red-600" focusBgColor="focus:bg-color-600"></Button>
                    </div>
                </div>
            </section>

            <div className="output">

                <div className='mb-4'>

                    <h3 className='text-2xl'>Text Summary</h3>

                    <div className='flex gap-4'>
                        <span>Word Count: {textSummary.wordCount}</span>|
                        <span>Character Count: {textSummary.charCount}</span>|
                        <span>Line Count: {textSummary.lineCount}</span>
                    </div>

                </div>

                <div className='max-w-full'>
                    <hr />
                    <h2 className="text-4xl">Preview</h2>
                    <p className='my-6 break-words'>{text.length > 0 ? text : "Enter something in textbox"}</p>
                </div>

            </div>
            <Copied show={displayCopied}></Copied>
        </main>
    )
}

TextForm.propTypes = {
    heading: PropTypes.string,
    placeholder: PropTypes.string
}

TextForm.defaultProps = {
    heading: "Heading",
    placeholder: "Enter Text"
}